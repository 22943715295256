<template>
  <div class="affiliate">
    <div class="hero">
      <h1>Affiliate Program</h1>
      <p>
        Join now and earn money by promoting our language services.
      </p>
    </div>
    <div class="features">
      <div v-for="feature in features" v-bind:key="feature.header">
        <h2>{{ feature.header }}</h2>
        <p>{{ feature.text }}</p>
      </div>
    </div>
    <div class="commission">
      <h2>Your commission with our payout models</h2>
      <div class="grid">
        <div v-for="commission in commissions" v-bind:key="commission.subscriptions">
          <h3>{{ commission.emoji }} {{ commission.subscriptions }} - {{ commission.rate }}</h3>
          <p>
            With {{ commission.subscriptions }} you gain {{ commission.rate }} revenue share.
          </p>
        </div>
      </div>
      <p class="center">
        Content contributors will be rewarded by higher revenue share regardless of their subscription count.
      </p>
    </div>
    <div class="contact">
      <p>
        Interested? Email us at <a href="mailto:affiliate@pocketlang.com">affiliate@pocketlang.com</a>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      features: [
        { header: "🔥 High conversion rate", text: "Our effective promo material means the people you send to our website have high chance of converting!" },
        { header: "🌎 Convert all geo locations", text: "We provide global campaigns and accept customers regardless of their location and country of origin." },
        { header: "💳 Generous commissions", text: "Extremely high commissions at 20-50% with lifetime revenue share!" },
        { header: "👩‍💼 Dedicated account manager", text: "Get individual support with any inquiries you might have - we're here to help." },
      ],
      commissions: [
        { emoji: "🥂", subscriptions: "1 active subscription", rate: "20%" },
        { emoji: "🥉", subscriptions: "25 active subscriptions", rate: "30%" },
        { emoji: "🥈", subscriptions: "50 active subscriptions", rate: "40%" },
        { emoji: "🥇", subscriptions: "100 active subscriptions", rate: "50%" },
      ],
    }
  },
  mounted() {
    document.title = "Affiliate Program - PocketLang.com"
  },
}
</script>

<style scoped lang="scss">
.affiliate {
  .hero {
    padding: 1rem;
    h1 {
      margin: 0;
    }
    min-height: 5rem;
    background-color: #faeee7;
    text-align: center;
  }
  .features {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0rem 1rem;
  }
  .features, .commission, .contact {
    max-width: 60rem;
    margin: 1rem auto;
  }
  @media(max-width:65rem) {
    .features, .commission, .contact {
      margin: 1rem;
    }
  }
  .commission {
    h2 {
      text-align: center;
    }
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0rem 1rem;
    }
    .center {
      text-align: center;
    }
  }
  @media(max-width:37rem) {
    .features {
      grid-template-columns: 1fr;
    }
  }
  @media(max-width:20rem) {
    .commission .grid {
      grid-template-columns: 1fr
    }
  }
}
</style>